.nav {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    font-family: 'Guardian Egyptian Web', 'Guardian Text Egyptian Web', Georgia, serif;
    font-weight: 400;
}

.ul-flex {
    text-align: center;
    border-bottom: 2px solid #333;

    /* ul */
    list-style: none;
    padding-left: 0;
    margin: 0 auto;
    /* flex */
    display: flex;
}

.ul-flex li {
    padding: 8px 5px 0;
    /* hack for res */
    height: 24px;
    line-height: 22px;
    overflow-y: hidden;
}
.ul-flex li:last-child {
    /* hack for res */
    padding-left: 0;
    padding-right: 8px;
    margin-right: 8px;
    width: 80px;
}
/*.ul-flex li:last-child {
    margin-left: 10px;
}*/

.li {
    color: #dcdcdc;
    width: calc(100% / 5);
}
.li:hover {
    color: #333;
    cursor: pointer;
}
.li-focus {
    color: white !important;
    background-color: #333;
}

/* logo */
.logo {
  animation: logo-spin infinite 20s linear;
  height: 16px;
}
@keyframes logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
