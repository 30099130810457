.charts > div {
  text-align: left;
  width: calc(320px - 22px); /* 10 (padding) * 2 + 2 (border)*/
  border: 1px solid #dcdcdc;
  display: inline-block;
  margin-right: -1px;
  margin-bottom: -1px;
  padding: 10px;
  padding-bottom: 5px;
  pointer-events: none;
  font-family: 'Guardian Text Egyptian Web', Georgia, serif;
  font-size: 16px;
  line-height: 20px;
}
.charts > div > * {
  pointer-events: all;
}
/* hotfix to align with svg */
.charts > div > div {
  height: 180px; /* width = 300, ratio = 0.6 */
  margin-bottom: 7px;
  overflow: hidden;
  text-align: left; /* for chart */
}

#section3 .canvas,
#section3 svg {
  cursor: pointer;
}

/* hotfix for card layout */
#section3 svg {
  width: 100% !important;
  height: 100% !important;
  margin-top: 0 !important;
  margin-bottom: 5px;
  box-sizing: border-box;
}
#plotDot svg,
#plotScatter svg {
  margin-left: -3px;
  margin-bottom: 3px;
}
#line svg,
#lineDiscrete svg {
  margin-left: -1px;
  margin-bottom: 4px;
}
#brokenBar100 .bar {
  margin-top: 48px;
}

/* temp: disable charts */
#colGroupStack100 > *,
#slopegraph > * {
  opacity: 0.3;
  background-color: #eaeaea;
  pointer-events: none;
}
