#section4 {
  min-height: calc(100vh - 180px);
}

.setup {
  margin: 20px 0;
  cursor: default;
  user-select: none;
}
.setup-p1,
.setup-p2 {
  color: #333;
  background-color: #f6f6f6; /* n-6 */
  font-family: monospace;
  width: calc(50% - 5px);
  box-sizing: border-box;
  padding: 15px 10px;
}
.setup-p1 div {
  line-height: 24px;
  margin-bottom: 6px;
}
.setup-p1 div:last-child {
  margin-bottom: 0;
}
.setup-p1 span {
  padding: 0 5px;
}
.setup-p2 > div {
  line-height: 24px;
}
@media (max-width: 960px) {
  .setup {
    display: block;
  }
  .setup-p1 {
    margin-bottom: 5px;
  }
  .setup-p1,
  .setup-p2 {
    width: 100%;
  }
}

.display-item {
  cursor: url('../assets/icon/toggle3.svg') 16 16, auto;
}
.display-separator:not(:first-child)::before {
  content: '| ';
}
.display-off {
  opacity: 0.25;
  text-decoration: line-through;
}

.palette {
  margin: 5px 0;
  padding-left: 0;
}
.palette li {
  width: 32px;
  height: 32px;
  border-radius: 1px;
  padding: 0;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: middle;
  cursor: url('../assets/icon/colorize.svg') 12 12, pointer;
}
.palette li:nth-child(2),
.palette li:nth-child(5),
.palette li:nth-child(8),
.palette li:nth-child(9) {
  margin-right: 10px;
}
.palette .li-custom {
  width: 106px;
  box-sizing: border-box;
  line-height: 30px;
  border: 2px solid black;
}
.li-custom input {
  background-color: transparent;
  font-family: monospace;
  font-size: 13px;
  text-align: center;
  width: 60px;
  margin-left: 20px;
  border: 0;
  outline: 0;
}
.color-picked {
  top: -8px !important;
  left: 12px;
}


/* TOTHINK: temp or not, sync with template */
.graph {
  color: #767676; /*n-2*/
  font-size: 13px;
  font-family: "Guardian Text Sans Web", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  /* editor only */
  padding: 6px 0 4px 0;
  border-top: 1px solid rgb(220, 220, 220);
  border-bottom: 1px solid rgb(220, 220, 220);
}
.chart {
  position: relative;
}
.header {
  margin-bottom: 30px;
}
.headline {
  color: black;
  font-size: 18px;
  font-family: 'GH Guardian Headline', Georgia, serif;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 12px;
}
.standfirst {
  font-family: 'Guardian Text Egyptian Web', Georgia, serif;
  font-size: 14px;
  line-height: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: 400;
}
.legend {
  line-height: 18px;
}
.legend-item {
  white-space: nowrap;
  display: inline-block;
  margin-right: 12px;
  position: relative;
}
.legend-color {
  position: absolute;
  top: 1px;
  display: inline-block;
  width: 6px;
  height: 12px;
  border-radius: 2px;
  /* to help selection, no need in templates */
  margin: 0 4px 4px 0;
  cursor: url('../assets/icon/colordrop.svg') 12 12, auto;
}
.legend-label {
  margin-left: 10px; /* 6+4 */
  pointer-events: all;
}

.axis-x,
.axis-y {
  color: #121212;
}
.axis-top-text {
  white-space: nowrap;
}
.label {
  color: #333;
  line-height: 18px;
  vertical-align: top;
}
.label-x {
  font-size: 12px;
}
.label-x span {
  word-break: break-word
}

footer {
  font-size: 12px;
  line-height: 16px;
  margin-top: 6px;
  padding-top: 8px;
  font-family: 'Guardian Text Sans Web', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  color: #767676;
}
footer > div {
  word-break: break-word;
}

#section4 svg {
  position: absolute;
  right: 0;
  top: -1px;
}
svg path {
  stroke-linejoin: round;
  /* for all but IE, use scale calc in template */
  vector-effect: non-scaling-stroke;
}
/* end of TOTHINK*/

