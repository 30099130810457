.row-flex {
  display: flex;
  justify-content: space-between;
}

/* input text and textarea */
.text,
.textarea {
  font-family: monospace;
  font-size: 13px;
  display: block;
  padding: 10px 5px;
  border: 1px solid #dcdcdc; /*n-4*/
  box-sizing: border-box;
}
.text:focus,
.textarea:focus{
  outline: none;
  border-color: #333;
}
.text {
  width: calc(80% - 5px);
  height: 35px;
  margin-bottom: 15px
}
.textarea {
  width: 100%;
  height: 58vh;
  min-height: 200px;
  margin-bottom: 30px;
  resize:vertical;
}
.file {
  width: 21%;
  height: 35px;
  cursor: pointer;
}
.file-shell {
  text-align: center;
  position: absolute;
  right: 0;
  width: 20%;
  padding-top: 11px;
  padding-left: 0;
  padding-right: 0;
  pointer-events: none;
  cursor: pointer;
}
