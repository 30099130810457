.table {
  overflow: scroll;
  max-height: calc(100vh - 230px);
  min-height: 200px;
  margin-bottom: 30px;
  /*border: 1px solid #dcdcdc;*/
}

table {
  font-family: monospace;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #dcdcdc; /*n-4*/
}
th {
    /*min-width: 120px;*/
}
tbody tr:nth-child(even) {
    background-color: #f6f6f6; /*n-6*/
}
thead tr {
    background-color: #eaeaea; /*n-5*/
    border-bottom: 1px solid #dcdcdc; /*n-4*/
}
th, td {
  padding: 7px;
  margin: 0;
  border-right: 1px solid #dcdcdc; /*n-4*/
}
th:last-child {
  border-right: 0;
}
th:first-child,
td:first-child {
  font-weight: bold;
  text-align: right;
  width: 20px;
  background-color: #eaeaea; /*n-5*/
}

/* color data types */
.string {}
.number {
  color: #197caa;
  text-align: right;
}
.date {
  color: #66a998;
  text-align: center;
}
.null {
  color: #d61d00;
  background-color: rgba(253, 173, 186, 0.2);
}

/* hide and show */
.col-hide,
.row-hide td {
  color: #dcdcdc !important;
}
