/* single line editor */
.DraftEditor-root,
.DraftEditor-root div {
  min-width: 2px; /* to keep visibility */
  display: inline-block;
  cursor: text;
}

[contenteditable] span {
  border-bottom: 2px solid transparent;
}
[contenteditable]:focus span {
  outline: none;
  border-bottom: 2px solid transparent;
  background-color: #f6f6f6;
}
[contenteditable]:hover span {
  border-bottom: 2px solid #333;
}
.axis-top-text [contenteditable] {
  white-space: nowrap !important;
}
.setup-p2 [contenteditable] span {
  border-bottom: 2px solid #333;
}

.txt[contenteditable]:hover,
.txt[contenteditable]:focus {
  outline: none;
  border-bottom: 2px solid #333;
  box-sizing: border-box;
}
