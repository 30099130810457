/* general setup */
#root {
  padding: 0 20px;
  box-sizing: border-box;
}

h1 {
  font-size: 1.75rem;
  margin-bottom: 0;
  font-family: 'GH Guardian Headline', Georgia, serif;
}
.f-app {
  font-size: 1rem;
  font-weight: normal;
}
.f-bar {
  font-family: 'Guardian Agate Sans 1 Web', monospace;
  color: #f1f1f1; /* n-5 */
  text-align: right;
  line-height: 16px;
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  cursor: text;
  box-sizing: border-box;
  vertical-align: top;
}
.f-bar:hover,
.f-bar:focus {
  outline: 0;
  border-bottom: 2px solid #333;
}

.section {
  margin: 60px auto 60px;
  max-width: 1284px;/*1024px;*/
  width: 100%;
  min-height: calc(100vh - 120px);
}
.section-full {
  margin-top: 80px;
  margin-bottom: 60px;
  max-width: 1284px;
  width: 100%;
  height: calc(100vh - 140px);
}
#section5 {
  min-height: 100px;
}

.l-section {
  /* layout like all sections */
  max-width: 1284px;/*1024px;*/
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.instruction {
  color: #333;
  font-family: 'Guardian Text Egyptian Web', Georgia, serif;
  font-size: 16px;
  line-height: 20px;
  line-height: 20px;
  margin: 15px 0;
}


/* buttons */
.button {
  font-size: 14px;
  background-color: white;
  width: 20%;
  min-width: 140px;
  outline: 0;
  padding: 8px;
  border: 1px solid #333;
  cursor: pointer;
}
.btn-off {
  opacity: 0.25;
  cursor: not-allowed;
}
/* section 1 */
.btn-import {
  width: 40%;
}
.btn-clear { opacity: 0.25 }
.btn-clear:hover { opacity: 1 }
/* section 2 */
.btn-create {
  min-width: 160px;
}

.test {
  font-size: 13px;
  visibility: hidden;
  white-space: nowrap;
}
